import { Component, inject, OnInit } from '@angular/core';
import { LayoutBaseComponent } from '../layout-base.component';
import { interval, Subscription } from 'rxjs';
import { ConfigService } from '../../../../../shared/src/lib/services/config.service';
import { AuthService } from '../../pages/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss'
})
export class UserCardComponent extends LayoutBaseComponent implements OnInit {

  protected overlayPanelOpened = false;

  protected userName!: string;
  protected email!: string;
  protected publicBasePath: string;
  protected siteKey!: string;
  protected subscriptionPlan: string;
  protected hasSubscriptionPlan: boolean | undefined = undefined;
  protected checkSiteKey!: Subscription;

  protected readonly configService = inject(ConfigService);
  protected readonly authService = inject(AuthService);
  private readonly translateService = inject(TranslateService);
  private readonly router = inject(Router);

  protected loading = true;
  protected isLoggedIn = false;

  protected get publicPath() {
    return `${this.publicBasePath}/${this.translateService.currentLang}/${this.siteKey}`;
  }

  constructor() {
    super();

    this.checkSiteKey = interval(100).subscribe(() => {

      try {
        this.siteKey = this.authService.userAccount?.siteKey ?? null;
        if (this.siteKey != null) {
          this.loading = false;
          this.bindSubscriptionPlan();
          this.binHasSubscriptionPlan();
          this.isLoggedIn = true;
          this.checkSiteKey.unsubscribe();
        } else {

        }
      } catch (e) {
        console.error(e)
        throw new Error("Error checking site Key")
      }
    });


  }

  private bindSubscriptionPlan() {
    if (this.authService.userAccount && this.authService.userAccount.subscriptionPlan) {
      this.subscriptionPlan = this.translateService.currentLang == 'ar' ?
        this.authService.userAccount?.subscriptionPlan.nameAr : this.authService.userAccount?.subscriptionPlan.name;
    }
  }

  private binHasSubscriptionPlan() {
    if (this.authService.userAccount) {
      this.hasSubscriptionPlan = this.authService.userAccount.hasActiveSubscription;
    }
  }

  ngOnInit(): void {
    this.userName = this.authService.userData?.given_name;
    this.email = this.authService.userData?.email;
    this.publicBasePath = this.configService.getBrokerAppBasePath();

    this.authService.isLoggedIn.subscribe(c => {
      this.isLoggedIn = c;
      if (c && this.authService.userAccount) {
        this.siteKey = this.authService.userAccount?.siteKey ?? '';
        this.userName = this.authService.userData?.given_name;
        this.email = this.authService.userData?.email;
        this.publicBasePath = this.configService.getBrokerAppBasePath();

      }
    })
  }

  protected navigateTo(op: OverlayPanel, route: string) {
    op.hide();
    this.router.navigateByUrl(route);
  }

  protected openPublicSite() {
    window.open(this.publicPath, "_blank");
  }

  signOut() {
    this.authService.signOut();
  }



}

import { ConfigService } from './../../../../../../shared/src/lib/services/config.service';
import { AuthService } from './../../../pages/auth/auth.service';
import { Component, inject, OnInit } from '@angular/core';
import { LayoutBaseComponent } from '../../layout-base.component';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-inner-layout-header',

  templateUrl: './inner-layout-header.component.html',
  styleUrl: './inner-layout-header.component.scss'
})
export class InnerLayoutHeaderComponent extends LayoutBaseComponent implements OnInit {

  protected userName!: string;
  protected publicBasePath: string;
  protected siteKey!: string;

  protected checkSiteKey!: Subscription;

  protected readonly configService = inject(ConfigService);
  protected readonly authService = inject(AuthService);
  constructor() {
    super();

    this.checkSiteKey = interval(100).subscribe(() => {

      try {
        this.siteKey = this.authService.userAccount?.siteKey ?? null;
        if (this.siteKey != null) {
          this.checkSiteKey.unsubscribe();
        }
      } catch {
        throw new Error("Error checking site Key")
      }
    })
  }



  ngOnInit(): void {
    this.userName = this.authService.userData?.given_name;
    this.publicBasePath = this.configService.getBrokerAppBasePath();
    this.siteKey = this.authService.userAccount?.siteKey ?? '';

    this.authService.isLoggedIn.subscribe(c => {
      if (c) {
        this.siteKey = this.authService.userAccount?.siteKey ?? ''
      }
    })
  }
}


<div class="container-fluid h-100 w-100">
    <app-header [hideAuthButtons]="true" />

    <div class="d-flex justify-content-center flex-column align-items-center text-center">
        <div class="my-auto">
            <svg-icon src="assets/images/icons/unauthorized.svg" [svgStyle]="{height:'40vh'}" />
        </div>
        <p class="h3">
            {{'messages.unauthorizedMessage' | translate}}
        </p>       
    </div>

</div>
import { Component, Input, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { generateUUID } from '../../../../../../shared/src/public-api';

@Component({
  selector: 'app-side-menu-dropdown',

  templateUrl: './side-menu-dropdown.component.html',
  styleUrl: './side-menu-dropdown.component.scss'
})
export class SideMenuDropdownComponent implements OnInit {

  private readonly router = inject(Router)
  protected visibility: boolean = false;

  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() items: Array<{
    label: string;
    route: string;
  }> = [];

  getCollapseLabel() {
    if (this.label) {
      return `${this.label.replaceAll('.', '_')}_Collapse`;
    }

    return generateUUID() + "_Collapse";
  }

  ngOnInit(): void {
    this.updateVisibility();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Your code to execute after navigation ends goes here
      this.updateVisibility();

    });
  }

  private updateVisibility(): void {
    const currentRoute = this.router.routerState.snapshot.url;
    const itemsRoutes = this.items.map(p => p.route);
    this.visibility = itemsRoutes.indexOf(currentRoute) > -1;
  }


  toggleVisibility() {
    this.visibility = !this.visibility;
  }



}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { LayoutModule } from '../../layout/layout.module';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../../shared/base.component';

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, LayoutModule, TranslateModule],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.scss'
})
export class UnauthorizedComponent {

}

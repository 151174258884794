import { AuthService } from './../../pages/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { ConfigService } from '../../../../../shared/src/lib/services/config.service';
import { SharedEvents } from '../../../../../shared/src/lib/events';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  private readonly _configService = inject(ConfigService);
  private readonly _translateService = inject(TranslateService);
  private readonly router = inject(Router);
  protected readonly authService = inject(AuthService);

  protected get isAr() { return localStorage.getItem('lang') == 'ar' };
  private get htmlElement() { return document.querySelector('html'); }
  private get headElement() { return document.querySelector('head'); }
  protected isUserLoggedIn: boolean | undefined = undefined;
  @Input('hideAuthButtons')
  public hideAuthButtons: boolean = false;
  protected isDebugMode = false;
  @Input('isAyuthLayout') isAyuthLayout = false;

  constructor() {

    this.authService.isLoggedIn.subscribe(result => this.isUserLoggedIn = result);
    this.router.events
      .pipe(
        filter((p: any): p is NavigationEnd => p instanceof NavigationEnd),
        map((p: NavigationEnd) => p)
      ).subscribe((p) => {
        this.hideAuthButtons = p.url.startsWith('/auth');
      })

  }

  ngOnInit(): void {

    const langValue = localStorage.getItem('lang');
    this.isDebugMode = this._configService.getEnableDebug();

    if (langValue) {
      this.setLanguage(langValue);
    } else {
      this.setLanguage('ar');
    }

    SharedEvents.SiteLanguageEvent.listener.subscribe(lang => {
      this._translateService.use(lang);
      localStorage.setItem('lang', lang);
      this.updateBootstrap(lang === 'ar' ? 'ar' : 'en');
      this.switchTitle(lang === 'ar' ? 'ar' : 'en');
    })
  }

  private setLanguage(langValue: string) {
    this._translateService.use(langValue);
    localStorage.setItem('lang', langValue);
    this.updateBootstrap(langValue === 'ar' ? 'ar' : 'en');
    this.switchTitle(langValue === 'ar' ? 'ar' : 'en');
    if (langValue === 'ar') {
      SharedEvents.SiteLanguageEvent.pushAr();
    } else {
      SharedEvents.SiteLanguageEvent.pushEn();
    }
  }

  switchLanguage(lang: 'ar' | 'en') {
    //this._translateService.use(lang);
    localStorage.setItem('lang', lang);
    location.reload();
  }

  private switchTitle(lang: 'ar' | 'en') {
    const title = document.querySelector('title');
    if (!title) {
      return;
    }

    switch (lang) {
      case 'ar':
        title.innerHTML = 'منصة وسيط';
        break;
      case 'en':
        title.innerHTML = 'Waseit Platform';
        break;
      default:
        break;
    }

  }

  private updateBootstrap(lang: 'ar' | 'en') {

    this.setHtmlElement(lang);
    let linkElement
    if (this.isAr) {
      // Load the CSS file for LTR direction
      linkElement = this.generateLinkElement(true);
    } else {
      // Load the CSS file for RTL direction
      linkElement = this.generateLinkElement(false);
    }

    const styleElement = document.querySelector('#bootstrap-style');
    if (styleElement)
      this.headElement?.removeChild(styleElement);

    this.headElement?.insertBefore(linkElement, this.headElement.firstChild);
  }
  private generateLinkElement(rtl: boolean) {
    const linkElement = document.createElement('link');
    linkElement.rel = "stylesheet";
    linkElement.type = "text/css";
    linkElement.href = `assets/bootstrap/bootstrap.${rtl ? 'rtl.' : ''}min.css`;
    linkElement.id = 'bootstrap-style';
    return linkElement;
  }

  private setHtmlElement(lang: 'ar' | 'en') {
    const dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.querySelector('html')?.setAttribute('lang', lang);
    document.querySelector('html')?.setAttribute('dir', dir);

  }


}

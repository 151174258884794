<app-header [isAyuthLayout]="true"/>
<div class="container-fluid">

  <div class="row flex-row-reverse d-flex justify-content-center align-items-center">

    <div class="col-6 ms-md-5 text-md-end text-center brand-container">
      <!-- <img src="assets/images/login_brand.svg" class="brand-img" /> -->
    </div>

    <div class="col-6 route-container">
      <router-outlet></router-outlet>
    </div>

  </div>

</div>


<div class="d-md-flex justify-content-between align-items-center mt-5 mt-md-0 mb-4">
  <p [innerHTML]="(translateKey.getKey('welcome1') | translate:{'username':userName}) | htmlParser"> </p>

  <div class="card">
    <div class="card-body py-2">
      <p class="card-title m-0">
        {{'visitYouSite' | translate}}
      </p>

      <a href="{{publicBasePath}}/{{siteKey}}" target="_blank">
        {{publicBasePath}}/{{siteKey}}
      </a>

    </div>
  </div>

</div>

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../../pages/auth/auth.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class HasSubscriptionActiveGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    const hasActiveSubscription = this.authService.userAccount?.canUseSystem;

    if(state.url.includes('subscription-management') || state.url.includes('auth'))
      return true;

    if (hasActiveSubscription == false) {
      this.router.navigateByUrl('/subscription-management')
      return false;
    }

    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    return this.canActivate(route, state);
  }
  
}

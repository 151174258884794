import { Component, OnDestroy, inject } from '@angular/core';
import { LayoutBaseComponent } from '../layout-base.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from '../../pages/auth/auth.service';
import { BrokerSharedEvents } from '../../shared/events';

@Component({
  selector: 'app-inner-layout',
  templateUrl: './inner-layout.component.html',
  styleUrl: './inner-layout.component.scss'
})
export class InnerLayoutComponent extends LayoutBaseComponent implements OnDestroy {

  private readonly _authService = inject(AuthService);
  protected showSideMenue = true;

  constructor() {
    super();
    document.documentElement.style.setProperty('--bs-body-bg', 'var(--bs-gray-200)');
    this.showSideMenue = this._authService.userAccount?.canUseSystem;
    BrokerSharedEvents.SubscriptionStatusUpdated.listener.subscribe(val => {
      this.showSideMenue = val;
    })

  }

  ngOnDestroy(): void {
    document.documentElement.style.setProperty('--bs-body-bg', 'var(--bs-white)');
  }


}

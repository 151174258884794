import { ConfigService } from './../../../shared/src/lib/services/config.service';
import { AuthService } from './pages/auth/auth.service';
import { Component, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Router, RouterOutlet, ActivatedRoute } from '@angular/router';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LayoutModule } from './layout/layout.module';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, BlockUIModule, LayoutModule],
  providers: [DatePipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private get htmlElement() { return document.querySelector('html'); }
  private get headElement() { return document.querySelector('head'); }

  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly configService = inject(ConfigService);
  private readonly toastr = inject(ToastrService);

  protected isRequireProfileSelection: boolean = true;

  constructor() {

    this.authService.isLoggedIn.subscribe(response => {
      const currentPage = this.router.url.split('/').reverse()[1];
      const allowedPages = ['auth'];
      if (!response && allowedPages.find(x => x == currentPage) == undefined) {
        this.router.navigate(['./auth/login']);
      }
    });
  }

  ngOnInit(): void { }

}

import { Routes } from '@angular/router';
import { AuthLayoutComponent, InnerLayoutComponent } from './layout/public.api';
import { inject } from '@angular/core';
import { AuthService, AuthGuard, IsAuthenticatedGuard, IsNotAuthenticatedGuard } from './pages/auth/auth.service';
import { UserPermissions } from '../../../shared/src/lib/public.api';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { HasSubscriptionActiveGuard } from './shared/guards/auth.has-valid-subscription';

const ValidateProfileSelection =
  () => inject(AuthService).isAuthenticated() && inject(AuthService).isRequireProfileSelection() == false;


export const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [IsNotAuthenticatedGuard],
    loadChildren: () => import('./pages/auth/auth.module').then(p => p.AuthModule)
  },
  {
    path: '',
    component: InnerLayoutComponent,
    canActivate: [IsAuthenticatedGuard, HasSubscriptionActiveGuard],
    canActivateChild: [HasSubscriptionActiveGuard],
    children: [
      {
        path: 'clients',
        canActivate: [
          ValidateProfileSelection,
          () => AuthGuard([
            UserPermissions.Broker.ClientsManagement_ViewAll,
            UserPermissions.Broker.ClientsManagement_ViewAll_ClientProfile,
            UserPermissions.TeamMember.ClientsManagement_ViewAll,
            UserPermissions.TeamMember.ClientsManagement_ViewAll_ClientProfile,
            UserPermissions.Broker.InterestRequests_ViewAll,
            UserPermissions.TeamMember.InterestRequests_ViewAll
          ])

        ],
        loadChildren: () => import('./pages/client-management/client-management.module').then(p => p.ClientManagementModule)
      },
      {
        path: 'advertising',
        canActivate: [
          ValidateProfileSelection,
          () => AuthGuard([
            UserPermissions.Broker.PropertyAds_ViewAll,
            UserPermissions.Broker.PropertyAds_Create,
            UserPermissions.TeamMember.PropertyAds_ViewAll,
            UserPermissions.TeamMember.PropertyAds_ViewBrokerAds,
            UserPermissions.TeamMember.PropertyAds_Create,
          ])
        ],
        loadChildren: () => import('./pages/advertising-management/advertising-management.module').then(p => p.AdvertisingManagementModule)
      },
      {
        path: 'settings',
        canActivate: [ValidateProfileSelection],
        loadChildren: () => import('./pages/settings/settings.module').then(p => p.SettingsModule)
      },
      {
        path: 'team-management',
        canActivate: [ValidateProfileSelection, () => AuthGuard([
          UserPermissions.Broker.TeamManagement_ManageTeam,
          UserPermissions.TeamMember.TeamManagement_ViewMembers,
        ])],
        loadChildren: () => import('./pages/teams-management/teams-management.module').then(p => p.TeamsManagementModule)
      },
      {
        path: 'dashboard',
        canActivate: [ValidateProfileSelection],
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(p => p.DashboardModule)
      },
      {
        path: 'request-management',
        canActivate: [ValidateProfileSelection],
        loadChildren: () => import('./pages/request-management/request-management.module').then(p => p.RequestManagementModule)
      },
      {
        path: 'subscription-management',
        canActivate: [ValidateProfileSelection],
        loadChildren: () => import('./pages/subscription-management/subscription-management.module').then(p => p.SubscriptionManagementModule)
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'

  }
];

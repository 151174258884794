@if(isDebugMode){


<div class="alert alert-danger px-5 rounded-0 py-1 fw-bold m-0 clickable" data-bs-toggle="modal"
  data-bs-target="#developmentEnvironment">
  <div class="text-center">
    <p class="d-inline-block clickable debug-title m-0 me-2" [innerHTML]="'debug.title' | translate">
    </p>
  </div>
</div>

<div class="modal fade" id="developmentEnvironment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <img src="assets/images/software.gif" alt="" height="100rem">
        </div>
        <p [innerHTML]="'debug.description' | translate"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{'close' | translate}}
        </button>

      </div>
    </div>
  </div>
</div>
}

<nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
  <div class="container-fluid px-5">

    <div class="d-flex w-100 justify-content-between align-items-center" [ngClass]="{'flex-column flex-lg-row': !isAyuthLayout}">
      <a class="navbar-brand" [ngClass]="{'d-none d-lg-block': !isAyuthLayout}" href="/dashboard">
        <img src="/assets/images/logos/Full_Color_Black.png" class="d-inline-block align-top logo-brand" alt="">
      </a>

      <div class="d-flex w-100 align-items-center justify-content-between justify-content-lg-end">

        <app-user-card />

        <div [ngClass]="{'d-none d-lg-block': !isAyuthLayout}">
          <app-switch-language-button />
        </div>
      </div>
    </div>
  </div>

</nav>

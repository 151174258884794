import { AuthService } from "./pages/auth/auth.service";
import { AuthInterceptor, HttpErrorInterceptor } from "./shared/public.api";



export function tokenGetter() {
  return localStorage.getItem(AuthService.accessTokenKey);
}



export function provideHttpAuthInterceptors(myInterceptor: AuthInterceptor) {
  return [myInterceptor];
}


export function provideHttpErrorInterceptors(myInterceptor: HttpErrorInterceptor) {
  return [myInterceptor];
}

import { SharedEvents } from "../../../../shared/src/lib/events";
import { TranslationKeys } from "../shared/helpers/translation-keys.helper";

export abstract class LayoutBaseComponent {
  isMobile: boolean = false;

  protected translateKey = new TranslationKeys('layout')

  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { InnerLayoutComponent } from './inner-layout/inner-layout.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SkeletonModule } from 'primeng/skeleton';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { InnerLayoutHeaderComponent } from './inner-layout/inner-layout-header/inner-layout-header.component';
import { HtmlParserPipe, LookupParserPipe } from '../../../../shared/src/public-api';
import { SideMenuDropdownComponent } from './side-menu/side-menu-dropdown/side-menu-dropdown.component';
import { UserCardComponent } from './user-card/user-card.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SwitchLanguageButtonComponent } from './SwitchLanguageButton/SwitchLanguageButton.component';

@NgModule({
  declarations: [
    AuthLayoutComponent,
    InnerLayoutComponent,
    HeaderComponent,
    SideMenuComponent,
    InnerLayoutHeaderComponent,
    SideMenuDropdownComponent,
    UserCardComponent,
    SwitchLanguageButtonComponent
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SkeletonModule,
    RouterModule,
    AngularSvgIconModule,
    HtmlParserPipe,
    OverlayPanelModule,
    LookupParserPipe
  ]
})
export class LayoutModule { }

import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedEvents } from '../../../../../shared/src/lib/events';

@Component({
  selector: 'app-switch-language-button',
  templateUrl: './SwitchLanguageButton.component.html',
  styleUrl: './SwitchLanguageButton.component.css',

})
export class SwitchLanguageButtonComponent {

  private readonly _translateService = inject(TranslateService);

  protected get isAr() { return localStorage.getItem('lang') == 'ar' };

  switchLanguage(lang: 'ar' | 'en') {
    localStorage.setItem('lang', lang);
    this._translateService.use(lang);
    if (lang === 'ar') {
      SharedEvents.SiteLanguageEvent.pushAr();
    } else {
      SharedEvents.SiteLanguageEvent.pushEn();
    }
  }
}

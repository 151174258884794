@if(isLoggedIn){
<div class="d-flex justify-content-between align-items-center clickable " dir="ltr" id="card_container"
    (click)="op.toggle($event)">
    <div>
        <svg-icon src="assets/images/icons/person.svg" [svgStyle]="{'height':'24px', 'fill':'var(--bs-primary)'}" />
    </div>

    <div class="mx-2 d-none d-lg-block" style="max-width: 150px;">
        <p class="text-truncate m-0">
            {{userName}}
        </p>
    </div>
    <div>
        <i class="bi bi-chevron-{{overlayPanelOpened ? 'up' : 'down'}}"></i>
    </div>
</div>
<p-overlayPanel #op (onShow)="overlayPanelOpened = true" (onHide)="overlayPanelOpened = false"
    [style]="{'max-width':'85vw'}">

    <ul class="list-group">
        <li class="list-group-item flush">
            <div class="card-item">
                <p class="fw-bold m-0">{{userName}}</p>
                <p class="text-wrap text-truncate fw-bold text-primary m-0" style="max-width: 200px;">
                    {{authService?.userAccount?.role | lookupParser}}
                </p>
                <p class="text-wrap text-truncate m-0" style="max-width: 200px;">
                    {{email}}
                </p>
            </div>
        </li>

        @if(hasSubscriptionPlan){
        <li class="list-group-item flush">
            <div class="card-item">
                <svg-icon src="assets/images/icons/crown.svg"
                    [svgStyle]="{'height':'24px', 'fill':'var(--bs-secondary)'}" />
                <span>
                    {{subscriptionPlan }}
                </span>
            </div>
        </li>
        <li class="list-group-item flush list-group-item-action" [title]="publicPath">
            <button class="btn btn-secondary w-100 text-start" type="button" (click)="openPublicSite()">
                <svg-icon src="assets/images/icons/domain-registration.svg" />
                {{'visitYouSite' |translate}}
            </button>
        </li>
        <li class="list-group-item flush list-group-item-action" (click)="navigateTo(op,'settings/site')">
            <button class="btn w-100 text-start">
                <svg-icon src="assets/images/icons/website.svg"
                    [svgStyle]="{'height':'24px', 'fill':'var(--bs-secondary)'}" />
                <span>
                    {{'settings.siteConfiguration' | translate }}
                </span>
            </button>
        </li>
        }

        <li class="list-group-item flush list-group-item-action" (click)="navigateTo(op,'settings/account')">
            <button class="btn w-100 text-start">
                <svg-icon src="assets/images/icons/account-settings.svg"
                    [svgStyle]="{'height':'24px', 'fill':'var(--bs-secondary)'}" />
                <span>
                    {{translateKey.getGeneralKey('accountSettings') | translate }}
                </span>
            </button>
        </li>

        @if(authService.isBroker()){
        <li class="list-group-item flush list-group-item-action" (click)="navigateTo(op,'subscription-management')">
            <button class="btn w-100 text-start" type="button">
                <svg-icon src="assets/images/icons/packages.svg"
                    [svgStyle]="{'height':'24px', 'fill':'var(--bs-secondary)'}" />
                <span>
                    {{'subscriptionManagement.title' | translate }}
                </span>
            </button>
        </li>
        }
        <li class="list-group-item flush list-group-item-action" (click)="signOut()">
            <button class="btn text-danger w-100 text-start" type="button">
                <svg-icon src="assets/images/icons/logout.svg"
                    [svgStyle]="{'height':'24px', 'fill':'var(--bs-secondary)'}" />
                <span>
                    {{'general.logout' | translate }}
                </span>
            </button>
        </li>
   
    </ul>
</p-overlayPanel>
}
<div class="inner-container">
  <app-header [hideAuthButtons]="true" />
  <div class="d-lg-flex justify-content-start h-100">
    @if(showSideMenue){
    <div class="">
      <app-side-menu />
    </div>
    }
    <div class="my-md-1 my-3 container-fluid pt-md-3 px-md-5 main px-2 px-md-0" style="overflow: hidden;">
      <!-- <app-inner-layout-header /> -->
      <router-outlet></router-outlet>
    </div>

  </div>

</div>
<li class="nav-item">
  <a class="nav-link" data-bs-toggle="collapse" (click)="toggleVisibility()" href="#{{getCollapseLabel()}}"
    role="button" [attr.aria-controls]="getCollapseLabel()" attr.aria-expanded="{{visibility ? 'true':'false'}}"
    [ngClass]="{'collapsed':!visibility}">
    <span>
      <i class="{{icon}} me-2"></i>
      {{label | translate}}
    </span>
  </a>
</li>
<ul class="nav flex-column collapse mx-2 px-1 bg-light" [ngClass]="{'show':visibility}" [id]="getCollapseLabel()">

  @for (navItem of items; track navItem) {

  <li class="nav-item sideNavigation">
    <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="[navItem.route]">
      <span class="ms-lg-3">
        {{navItem.label| translate}}
      </span>
    </a>
  </li>
  }


</ul>